import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Login,
  Dashboard,
  AddStudent,
  AddCaste,
  AddClass,
  AddCourse,
  AddCourseDuration,
  AddFees,
  AllStudents,
  StudentDetails,
  AddAdmissionFees,
  AddBatch
} from "../../pages";
const NavigationRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/all-students" element={<AllStudents />} />
        <Route path="/student-details/:id" element={<StudentDetails />} />
        <Route path="/add-student" element={<AddStudent />} />
        <Route path="/add-caste" element={<AddCaste />} />
        <Route path="/add-class" element={<AddClass />} />
        <Route path="/add-course" element={<AddCourse />} />
        <Route path="/add-course-duration" element={<AddCourseDuration />} />
        <Route path="/add-fees" element={<AddFees />} />
        <Route path="/add-admission-fees" element={<AddAdmissionFees />} />
        <Route path="/add-batch" element={<AddBatch />} />
      </Routes>
    </BrowserRouter>
  );
};
export default NavigationRouter;
