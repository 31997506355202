import React, { useState, useEffect } from "react";
import SideMenuData from "./side-menu-data";
import { Grid, IconButton } from "@mui/material";
import "./side-menu.css";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Button } from "@mui/material";
import { IoLogOutSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import Logo from "./../../images/logo-2.png";
import Logo2 from "./../../images/logo.png";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
export const SideMenu = (props) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  let CurrentPagePath = location.pathname;
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={12} md={2}>
            <SideMenuData />
          </Grid>
          <Grid item xs={12} lg={10}>
            <div className="tutot-right-side-container">
              <div className="tutor-dashboard-page-header-container">
                <div className="tutor-dashboard-page-header-content-wrapper">
                  <div>
                    {isMatch ? (
                      <div>
                        <IconButton
                          onClick={() => {
                            setIsDrawerOpen(true);
                          }}
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div> </div>
                    )}

                    <Drawer
                      anchor="left"
                      open={isDrawerOpen}
                      onClose={handleDrawerClose}
                    >
                      <div>
                        <div className="tutor-panel-sider-content-wraper">
                          <div className="drawer-header-main">
                            <img src={Logo} alt="logo" width="90px" />
                            <IconButton
                              onClick={() => {
                                handleDrawerClose();
                              }}
                              className="app-bar-component-drawer-close-btn"
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <div className="side-menu-data-list-main">
                            <ul className="side-menu-ul">
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/dashboard"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/dashboard");
                                  }}
                                >
                                  <DashboardIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    dashboard
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/all-students"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/all-students");
                                  }}
                                >
                                  <Diversity3Icon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    all students
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-student"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-student");
                                  }}
                                >
                                  <GroupAddIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    Add Student
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-caste"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-caste");
                                  }}
                                >
                                  <BookmarkAddIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    Add Caste
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-class"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-class");
                                  }}
                                >
                                  <AddBusinessIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    Add class
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-course"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-course");
                                  }}
                                >
                                  <DomainAddIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    Add Course
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-course-duration"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-course-duration");
                                  }}
                                >
                                  <AddAlarmIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    Add Course Duration
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-fees"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-fees");
                                  }}
                                >
                                  <CurrencyExchangeIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                    Add fees
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-admission-fees"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-admission-fees");
                                  }}
                                >
                                  <CurrencyRupeeIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                  add admission fees
                                  </span>
                                </Button>
                              </li>
                              <li className="tutor-panel-sider-list-item">
                                <Button
                                  variant="text"
                                  className={
                                    CurrentPagePath === "/add-batch"
                                      ? "side-menu-active-page"
                                      : "side-menu-page"
                                  }
                                  onClick={() => {
                                    navigate("/add-batch");
                                  }}
                                >
                                  <BatchPredictionIcon className="side-menu-icon" />
                                  <span className="side-menu-page-title">
                                  add batch
                                  </span>
                                </Button>
                              </li>
                              <br />
                            </ul>
                          </div>
                          <div className="first-tutor-panel-sider-log-out-container">
                            <Button
                              variant="text"
                              className="logout-button"
                              onClick={() => {
                                signOut(auth).then(() => {
                                  navigate("/");
                                });
                              }}
                            >
                              <span>Logout</span>
                              <IoLogOutSharp className="side-menu-icon" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Drawer>
                  </div>

                  <div className="side-menu-header">
                    <div></div>
                    <div className="tutor-dashboard-page-header-content-wrapper-profile-logo">
                      <p className="side-menu-header-username">Admin</p>
                      <img
                        src={Logo2}
                        alt="Profile"
                        className="side-menu-header-profile-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"side-menu-children-data"}>{props.children}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SideMenu;
