import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../../components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "../../components";
import { useAlert } from "react-alert";
import firebase from "../../config/firebase";
import {
  query,
  getFirestore,
  collection,
  doc,
  onSnapshot,
  addDoc,
  updateDoc,
} from "firebase/firestore";
const AddStudent = () => {
  const alert = useAlert();
  const auth = getAuth();
  const db = getFirestore(firebase);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [caste, setCaste] = useState("");
  const [className, setClassName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [course, setCourse] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [fees, setFees] = useState("");
  const [dateOfAdmission, setDateOfAdmission] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [email, setEmail] = useState("");
  const [casteList, setCasteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [courseDurationList, setCourseDurationList] = useState([]);
  const [feesList, setFeesList] = useState([]);
  const [admissionFees, setAdmissionFees] = useState("");
  const [admissionFeesList, setAdmissionFeesList] = useState([]);
  const [batch, setBatch] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      //caste
      const q = query(collection(db, "caste"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const allCaste = [];
        querySnapshot.forEach((doc) => {
          allCaste.push(doc.data());
          setCasteList([...allCaste]);
        });
      });

      //class
      const qClass = query(collection(db, "class"));
      onSnapshot(qClass, (querySnapshot) => {
        const allClass = [];
        querySnapshot.forEach((doc) => {
          allClass.push(doc.data());
          setClassList([...allClass]);
        });
      });

      //course
      const qCourse = query(collection(db, "course"));
      onSnapshot(qCourse, (querySnapshot) => {
        const allCourse = [];
        querySnapshot.forEach((doc) => {
          allCourse.push(doc.data());
          setCourseList([...allCourse]);
        });
      });

      //course duration
      const qCourseDuration = query(collection(db, "courseDuration"));
      onSnapshot(qCourseDuration, (querySnapshot) => {
        const allCourseDuration = [];
        querySnapshot.forEach((doc) => {
          allCourseDuration.push(doc.data());
          setCourseDurationList([...allCourseDuration]);
        });
      });
      //fees
      const qFees = query(collection(db, "fees"));
      onSnapshot(qFees, (querySnapshot) => {
        const allFees = [];
        querySnapshot.forEach((doc) => {
          allFees.push(doc.data());
          setFeesList([...allFees]);
        });
      });
      //admisson fees
      const qAdmissonFees = query(collection(db, "admissionFees"));
      onSnapshot(qAdmissonFees, (querySnapshot) => {
        const allAdmissionFees = [];
        querySnapshot.forEach((doc) => {
          allAdmissionFees.push(doc.data());
          setAdmissionFeesList([...allAdmissionFees]);
        });
      });
      const qBatch = query(collection(db, "addBatch"));
      onSnapshot(qBatch, (querySnapshot) => {
        const allBatch = [];
        querySnapshot.forEach((doc) => {
          allBatch.push(doc.data());
          setBatch([...allBatch]);
        });
      });
    }
    fetchMyAPI();
  }, []);
  const addHandler = async () => {
    setLoading(true);
    if (fullName === "") {
      alert.error(<div className="error-message">Full name required!</div>);
      setLoading(false);
    } else if (fatherName === "") {
      alert.error(<div className="error-message">father's name required!</div>);
      setLoading(false);
    } else if (caste === "") {
      alert.error(<div className="error-message">caste required!</div>);
      setLoading(false);
    } else if (className === "") {
      alert.error(<div className="error-message">class required!</div>);
      setLoading(false);
    } else if (gender === "") {
      alert.error(<div className="error-message">gender required!</div>);
      setLoading(false);
    } else if (dateOfBirth === "") {
      alert.error(<div className="error-message">date of birth required!</div>);
      setLoading(false);
    } else if (course === "") {
      alert.error(<div className="error-message">course required!</div>);
      setLoading(false);
    } else if (courseDuration === "") {
      alert.error(
        <div className="error-message">course duration required!</div>
      );
      setLoading(false);
    } else if (admissionFees === "") {
      alert.error(
        <div className="error-message">admission fees required!</div>
      );
      setLoading(false);
    } else if (selectedBatch === "") {
      alert.error(<div className="error-message">batch required!</div>);
      setLoading(false);
    } else if (fees === "") {
      alert.error(<div className="error-message">fees required!</div>);
      setLoading(false);
    } else if (dateOfAdmission === "") {
      alert.error(
        <div className="error-message">date Of Admission required!</div>
      );
      setLoading(false);
    } else if (whatsappNumber === "") {
      alert.error(
        <div className="error-message">whatsapp number required!</div>
      );
      setLoading(false);
    } else if (whatsappNumber.length !== 11) {
      alert.error(<div className="error-message">invalid whatsapp number</div>);
      setLoading(false);
    } else if (
      email !== "" &&
      !email
        .slice(0)
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      alert.error(<div className="error-message">invalid email address</div>);
      setLoading(false);
    } else {
      const months = [];
      if (Number(courseDuration) === 1) {
        months.push(true);
      } else if (Number(courseDuration) === 2) {
        months.push(true, false);
      } else if (Number(courseDuration) === 3) {
        months.push(true, false, false);
      } else if (Number(courseDuration) === 4) {
        months.push(true, false, false, false);
      } else if (Number(courseDuration) === 5) {
        months.push(true, false, false, false, false);
      } else if (Number(courseDuration) === 6) {
        months.push(true, false, false, false, false, false);
      }
      let newStudent = {
        fullName: fullName,
        fatherName: fatherName,
        caste: caste,
        className: className,
        gender: gender,
        dateOfBirth: dateOfBirth,
        course: course,
        courseDuration: courseDuration,
        fees: fees,
        dateOfAdmission: dateOfAdmission,
        whatsappNumber: whatsappNumber,
        email: email,
        status: "active",
        admissionFees: admissionFees,
        months: months,
        totalFessCollected: Number(fees),
        batch: selectedBatch,
      };
      const docRef = await addDoc(collection(db, "students"), newStudent);
      alert.success(<div className="error-message">new student added</div>);
      const frankDocRef = doc(db, "students", docRef.id);
      await updateDoc(frankDocRef, {
        uid: docRef.id,
      });
      setLoading(false);
      setFullName("");
      setFatherName("");
      setCaste("");
      setClassName("");
      setGender("");
      setDateOfBirth("");
      setCourse("");
      setCourseDuration("");
      setFees("");
      setDateOfAdmission("");
      setWhatsappNumber("");
      setEmail("");
    }
  };
  return (
    <SideMenu>
      <h1 style={{ marginBottom: "15px" }}>Add student</h1>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div>
              <p className="input-title">Full Name</p>
              <input
                type="text"
                className="input"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Father's Name</p>
            <input
              type="text"
              placeholder="Father's name"
              value={fatherName}
              onChange={(value) => setFatherName(value.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Caste</p>
            <select
              onChange={(e) => {
                setCaste(e.target.value);
              }}
            >
              <option value="" selected={caste === "" && true}>
                Select caste
              </option>
              {casteList.map((v, i) => {
                return (
                  <option key={i} value={v.caste}>
                    {v.caste}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Class</p>
            <select onChange={(e) => setClassName(e.target.value)}>
              <option value="" selected={className === "" && true}>
                Select class
              </option>
              {classList.map((v, i) => {
                return (
                  <option key={i} value={v.class}>
                    {v.class}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Gender</p>
            <select onChange={(e) => setGender(e.target.value)}>
              <option value="" selected={gender === "" && true}>
                Select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Date of Birth</p>
            <input
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Course</p>
            <select onChange={(e) => setCourse(e.target.value)}>
              <option value="" selected={course === "" && true}>
                Select course
              </option>
              {courseList.map((v, i) => {
                return (
                  <option key={i} value={v.course}>
                    {v.course}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Course Duration</p>
            <select onChange={(e) => setCourseDuration(e.target.value)}>
              <option value="" selected={courseDuration === "" && true}>
                Select course duration
              </option>
              {courseDurationList.map((v, i) => {
                return (
                  <option key={i} value={v.courseDuration}>
                    {v.courseDuration}{" "}
                    {v.courseDuration === "1" ? "Month" : "Months"}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Admission Fees</p>
            <select onChange={(e) => setAdmissionFees(e.target.value)}>
              <option value="" selected={admissionFees === "" && true}>
                Select admission fees
              </option>
              {admissionFeesList.map((v, i) => {
                return (
                  <option key={i} value={v.admissionFees}>
                    {v.admissionFees} PKR
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Batch</p>
            <select onChange={(e) => setSelectedBatch(e.target.value)}>
              <option value="" selected={selectedBatch === "" && true}>
                Select batch
              </option>
              {batch.map((v, i) => {
                return (
                  <option key={i} value={v.batch}>
                    Batch {v.batch}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Fees</p>
            <select onChange={(e) => setFees(e.target.value)}>
              <option value="" selected={fees === "" && true}>
                Select fees
              </option>
              {feesList.map((v, i) => {
                return (
                  <option key={i} value={v.fees}>
                    {v.fees} PKR
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Date of Admission</p>
            <input
              type="date"
              value={dateOfAdmission}
              onChange={(e) => setDateOfAdmission(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">WhatsApp Numbar</p>
            <input
              type="number"
              placeholder="WhatsApp Numbar"
              value={whatsappNumber}
              onChange={(e) => setWhatsappNumber(e.target.value)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <p className="input-title">Email Address (Optional)</p>
            <input
              type="email"
              placeholder="Email Address (Optional)"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={10} lg={10} md={10} sm={8} xs={12}></Grid>
          <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
            <Button
              title="Add"
              buttonType={1}
              onClick={addHandler}
              isLoading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </SideMenu>
  );
};
export default AddStudent;
