import React, { useState } from "react";
import "./login.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const LoginHandler = () => {
    setLoading(true);
    if (email === "") {
      setLoading(false);
      setMessageType("error");
      setMessage("Email required!");
    } else if (password === "") {
      setLoading(false);
      setMessageType("error");
      setMessage("Password required!");
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setMessageType("success");
          setMessage("Signed in");
          setLoading(false);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        })
        .catch((error) => {
          const errorMessage = error.message;
          setMessageType("error");
          setMessage(errorMessage);
          setLoading(false);
        });
    }
  };
  return (
    <div className="login-container">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
          <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={3} sm={3} xs={12}></Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <div className="login-form-container">
                    <h1 className="login-from-heading">Login as a Admin</h1>
                    <br />
                    <br />
                    <p className="input-title">Email Address</p>
                    <input
                      type="email"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(value) => setEmail(value.target.value)}
                    />
                    <br />
                    <p className="input-title">Email Address</p>
                    <input
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(value) => setPassword(value.target.value)}
                    />
                    <p className={messageType}>{message}</p>
                    <Button
                      title="Log in"
                      buttonType={1}
                      onClick={LoginHandler}
                      isLoading={loading}
                    />
                  </div>
                </Grid>
                <Grid item xl={4} lg={4} md={3} sm={6} xs={12}></Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Login;
