import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../../components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { useAlert } from "react-alert";
import firebase from "../../config/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import {
  query,
  getFirestore,
  collection,
  doc,
  onSnapshot,
  deleteDoc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
const AddAdmissionFees = () => {
  const alert = useAlert();
  const auth = getAuth();
  const navigate = useNavigate();
  const db = getFirestore(firebase);
  const [admissionFees, setAdmissionFees] = useState("");
  const [loading, setLoading] = useState(true);
  const [admissionFeesList, setAdmissionFeesList] = useState([]);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      const q = query(collection(db, "admissionFees"));
      onSnapshot(q, (querySnapshot) => {
        setLoading(false);
        const allAdmissionFees = [];
        querySnapshot.forEach((doc) => {
          allAdmissionFees.push(doc.data());
          setAdmissionFeesList([...allAdmissionFees]);
        });
      });
    }
    fetchMyAPI();
  }, []);
  const addHandler = async () => {
    if (admissionFees === "") {
      alert.error(<div className="error-message">admission fees required!</div>);
    } else {
      const docRef = await addDoc(collection(db, "admissionFees"), {
        admissionFees: admissionFees,
      });
      setAdmissionFees("");
      alert.success(<div className="error-message">new admission fees added</div>);
      const frankDocRef = doc(db, "admissionFees", docRef.id);
      await updateDoc(frankDocRef, {
        uid: docRef.id,
      });
    }
  };
  const deleteHander = async ({ value, uid }) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete ?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      swal("Deleted!", ``, "success");
      await deleteDoc(doc(db, "admissionFees", uid));
    }
  };
  return (
    <SideMenu>
      <h1 style={{ marginBottom: "15px" }}>Add Admission Fees</h1>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={10} lg={10} md={9} sm={8} xs={12}>
            <div>
              <p className="input-title">Admission Fees</p>
              <input
                type="number"
                className="input"
                placeholder="Admission Fees"
                value={admissionFees}
                onChange={(e) => setAdmissionFees(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <p className="input-title"></p>
            <Button className="add-caste-button" onClick={addHandler}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <h1 style={{ marginBottom: "15px", marginTop: "20px" }}></h1>

      {loading ? (
        <p className="loading">Loading...</p>
      ) : admissionFeesList.length === 0 ? (
        <p>No data available</p>
      ) : (
        <div className="caste-box-main">
          {admissionFeesList.map((v, i) => {
            return (
              <div key={i} className="caste-box">
                <p>{v.admissionFees} PKR</p>
                <Button
                  className="delete-button"
                  onClick={() => deleteHander({ value: v.admissionFees, uid: v.uid })}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </SideMenu>
  );
};
export default AddAdmissionFees;
