import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { SideMenu } from "../../components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress } from "@mui/material";
import { useAlert } from "react-alert";
import firebase from "../../config/firebase";
import "./index.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import QRCode from "./qr-code";
import Logo from "./../../images/logo.png";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  query,
  getFirestore,
  collection,
  doc,
  onSnapshot,
  updateDoc,
  where,
} from "firebase/firestore";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const StudentDetails = () => {
  const alert = useAlert();
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.slice(17);
  const db = getFirestore(firebase);
  const [fees, setFees] = useState("");
  const [loading, setLoading] = useState(true);
  const [feesList, setFeesList] = useState();
  const [currentStudent, setCurrentStudent] = useState();
  const [open, setOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [caste, setCaste] = useState("");
  const [className, setClassName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [course, setCourse] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [dateOfAdmission, setDateOfAdmission] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [email, setEmail] = useState("");
  const [classList, setClassList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [courseDurationList, setCourseDurationList] = useState([]);
  const [casteList, setCasteList] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [url, setUrl] = useState();
  const [months, setMonths] = useState([]);
  const [totalFessCollected, setTotalFessCollected] = useState(0);
  const [batch, setBatch] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [admissionFees, setAdmissionFees] = useState("");
  const [admissionFeesList, setAdmissionFeesList] = useState([]);
  const [dateModal, setDateModal] = useState(false);
  const [passedDate, setPassedDate] = useState("");
  const [options, setOptions] = useState({
    ecLevel: "M", //The error correction level of the QR Code
    enableCORS: false, //Enable crossorigin attribute
    size: 250, //The size of the QR Code
    quietZone: 10, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
    bgColor: "white", //Background color
    fgColor: "#000", //Foreground color
    logoImage: Logo, //The logo image. It can be a url/path or a base64 value
    logoWidth: 50,
    logoHeight: 50,
    logoOpacity: 1,
    qrStyle: "squares", //Style of the QR Code modules - dots or squares
  });
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      const q = query(collection(db, "students"), where("uid", "==", path));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setCurrentStudent(doc.data());
          let data = doc.data();
          setTotalFessCollected(Number(data.totalFessCollected));
          setMonths(data.months);
          setFullName(data.fullName);
          setFatherName(data.fatherName);
          setCaste(data.caste);
          setClassName(data.className);
          setGender(data.gender);
          setDateOfBirth(data.dateOfBirth);
          setCourse(data.course);
          setCourseDuration(data.courseDuration);
          setFees(data.fees);
          setDateOfAdmission(data.dateOfAdmission);
          setWhatsappNumber(data.whatsappNumber);
          setSelectedBatch(data.batch);
          setAdmissionFees(data.admissionFees);
          setEmail(data.email);
          if (data.status === "passed") {
            setUrl(`https://verification.ihunar.com/user-verification/${path}`);
          }
          setLoading(false);
        });
      });
    }
    fetchMyAPI();
  }, []);
  useEffect(() => {
    async function fetchMyAPI() {
      //caste
      const q = query(collection(db, "caste"));
      onSnapshot(q, (querySnapshot) => {
        const allCaste = [];
        querySnapshot.forEach((doc) => {
          allCaste.push(doc.data());
          setCasteList([...allCaste]);
        });
      });

      //class
      const qClass = query(collection(db, "class"));
      onSnapshot(qClass, (querySnapshot) => {
        const allClass = [];
        querySnapshot.forEach((doc) => {
          allClass.push(doc.data());
          setClassList([...allClass]);
        });
      });

      //course
      const qCourse = query(collection(db, "course"));
      onSnapshot(qCourse, (querySnapshot) => {
        const allCourse = [];
        querySnapshot.forEach((doc) => {
          allCourse.push(doc.data());
          setCourseList([...allCourse]);
        });
      });

      //course duration
      const qCourseDuration = query(collection(db, "courseDuration"));
      onSnapshot(qCourseDuration, (querySnapshot) => {
        const allCourseDuration = [];
        querySnapshot.forEach((doc) => {
          allCourseDuration.push(doc.data());
          setCourseDurationList([...allCourseDuration]);
        });
      });
      //fees
      const qFees = query(collection(db, "fees"));
      onSnapshot(qFees, (querySnapshot) => {
        const allFees = [];
        querySnapshot.forEach((doc) => {
          allFees.push(doc.data());
          setFeesList([...allFees]);
        });
      });

      const qBatch = query(collection(db, "addBatch"));
      onSnapshot(qBatch, (querySnapshot) => {
        const allBatch = [];
        querySnapshot.forEach((doc) => {
          allBatch.push(doc.data());
          setBatch([...allBatch]);
        });
      });

      //admisson fees
      const qAdmissonFees = query(collection(db, "admissionFees"));
      onSnapshot(qAdmissonFees, (querySnapshot) => {
        const allAdmissionFees = [];
        querySnapshot.forEach((doc) => {
          allAdmissionFees.push(doc.data());
          setAdmissionFeesList([...allAdmissionFees]);
        });
      });
    }
    fetchMyAPI();
  }, []);
  const LeaveHander = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to leave this student?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      swal(
        "Leave!",
        `"${currentStudent.fullName}" leave the ${currentStudent.course} course`,
        "success"
      );
      const frankDocRef = doc(db, "students", path);
      await updateDoc(frankDocRef, {
        status: "leave",
        leaveDate: moment().format(),
      });
    }
  };
  const PassedHander = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to Pass this student?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      setDateModal(true);
      // alert("fd");
      // swal(
      //   "Passed!",
      //   `"${currentStudent.fullName}" passed the ${currentStudent.course} course`,
      //   "success"
      // );
      // const frankDocRef = doc(db, "students", path);
      // await updateDoc(frankDocRef, {
      //   status: "passed",
      //   passedDate: moment().format(),
      // });

      // setOptions({
      //   ecLevel: "M", //The error correction level of the QR Code
      //   enableCORS: false, //Enable crossorigin attribute
      //   size: 250, //The size of the QR Code
      //   quietZone: 10, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
      //   bgColor: "white", //Background color
      //   fgColor: "#000", //Foreground color
      //   logoImage: Logo, //The logo image. It can be a url/path or a base64 value
      //   logoWidth: 100,
      //   logoHeight: 100,
      //   logoOpacity: 1,
      //   qrStyle: "squares", //Style of the QR Code modules - dots or squares
      // });
      setUrl(`https://verification.ihunar.com/user-verification/${path}`);
    }
  };
  const PassedHander2 = async () => {
    if (passedDate === "") {
      alert.error(<div className="error-message">passed date required!</div>);
    } else {
      const frankDocRef = doc(db, "students", path);
      await updateDoc(frankDocRef, {
        status: "passed",
        passedDate: passedDate,
      });
      setUrl(`https://verification.ihunar.com/user-verification/${path}`);
      setDateModal(false);
    }
  };
  const updateHandler = async () => {
    setUpdateLoading(true);
    if (fullName === "") {
      alert.error(<div className="error-message">Full name required!</div>);
      setUpdateLoading(false);
    } else if (fatherName === "") {
      alert.error(<div className="error-message">father's name required!</div>);
      setUpdateLoading(false);
    } else if (caste === "") {
      alert.error(<div className="error-message">caste required!</div>);
      setUpdateLoading(false);
    } else if (className === "") {
      alert.error(<div className="error-message">class required!</div>);
      setUpdateLoading(false);
    } else if (gender === "") {
      alert.error(<div className="error-message">gender required!</div>);
      setUpdateLoading(false);
    } else if (dateOfBirth === "") {
      alert.error(<div className="error-message">date of birth required!</div>);
      setUpdateLoading(false);
    } else if (course === "") {
      alert.error(<div className="error-message">course required!</div>);
      setUpdateLoading(false);
    } else if (courseDuration === "") {
      alert.error(
        <div className="error-message">course duration required!</div>
      );
      setUpdateLoading(false);
    } else if (admissionFees === "") {
      alert.error(
        <div className="error-message">admission fees required!</div>
      );
      setLoading(false);
    } else if (selectedBatch === "") {
      alert.error(<div className="error-message">batch required!</div>);
      setLoading(false);
    } else if (fees === "") {
      alert.error(<div className="error-message">fees required!</div>);
      setUpdateLoading(false);
    } else if (dateOfAdmission === "") {
      alert.error(
        <div className="error-message">date Of Admission required!</div>
      );
      setUpdateLoading(false);
    } else if (whatsappNumber === "") {
      alert.error(
        <div className="error-message">whatsapp number required!</div>
      );
      setUpdateLoading(false);
    } else if (whatsappNumber.length !== 11) {
      alert.error(<div className="error-message">invalid whatsapp number</div>);
      setUpdateLoading(false);
    } else if (
      email !== "" &&
      !email
        .slice(0)
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      alert.error(<div className="error-message">invalid email address</div>);
      setUpdateLoading(false);
    } else {
      const frankDocRef = doc(db, "students", path);
      await updateDoc(frankDocRef, {
        fullName: fullName,
        fatherName: fatherName,
        caste: caste,
        className: className,
        gender: gender,
        dateOfBirth: dateOfBirth,
        course: course,
        courseDuration: courseDuration,
        fees: fees,
        dateOfAdmission: dateOfAdmission,
        whatsappNumber: whatsappNumber,
        email: email,
        admissionFees: admissionFees,
        batch: selectedBatch,
      });
      alert.success(<div className="error-message">successfully updated</div>);
      setUpdateLoading(false);
      setOpen(false);
    }
  };

  const monthFeesHandler = async (trueIndex) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update the payment?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      swal("Success!", `Monthly fees successfully added`, "success");
      months[trueIndex] = true;
      let newPrice = Number(totalFessCollected) + Number(fees);
      const frankDocRef = doc(db, "students", path);
      await updateDoc(frankDocRef, {
        months: months,
        totalFessCollected: newPrice,
      });
    }
  };

  const isAtLeastOneRemaning = months.some((obj) => obj === false);
  return (
    <SideMenu>
      <h1 style={{ marginBottom: "15px" }}>Student details</h1>
      <div className="student-details-header">
        <Button onClick={() => window.history.back()} className="back-button">
          <KeyboardBackspaceIcon
            style={{ color: "#98189a", width: "30px", height: "30px" }}
          />
        </Button>
        {loading ? (
          <CircularProgress size={25} style={{ color: "#98189a" }} />
        ) : (
          <div
            className="student-details-status-box"
            style={{
              backgroundColor:
                currentStudent.status === "active"
                  ? "#00a75f"
                  : currentStudent.status === "leave"
                  ? "#dd4b39"
                  : currentStudent.status === "passed" && "#f39c12",
            }}
          >
            <p>{currentStudent.status}</p>
          </div>
        )}
      </div>

      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        <>
          {currentStudent.status === "active" && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xl={10} lg={10} md={10} sm={8} xs={6}></Grid>
                <Grid item xl={2} lg={2} md={2} sm={4} xs={6}>
                  <Button
                    title="Add"
                    className="edit-button"
                    onClick={() => setOpen(true)}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">Full Name</p>
                  <p className="student-details-value">
                    {currentStudent.fullName}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">Father's Name</p>
                  <p className="student-details-value">
                    {currentStudent.fatherName}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">Caste</p>
                  <p className="student-details-value">
                    {currentStudent.caste}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">class Name</p>
                  <p className="student-details-value">
                    {currentStudent.className}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">gender</p>
                  <p className="student-details-value">
                    {currentStudent.gender}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">date Of Birth</p>
                  <p className="student-details-value">
                    {currentStudent.dateOfBirth}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">course and Batch</p>
                  <p className="student-details-value">
                    {currentStudent.course} Batch({currentStudent.batch})
                  </p>
                </div>
              </Grid>

              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">course Duration</p>
                  <p className="student-details-value">
                    {currentStudent.courseDuration}{" "}
                    {currentStudent.courseDuration === "1" ? "Month" : "Months"}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">fees</p>
                  <p className="student-details-value">{currentStudent.fees}</p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">date Of Admission</p>
                  <p className="student-details-value">
                    {currentStudent.dateOfAdmission}
                  </p>
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">whatsapp Number</p>
                  <p className="student-details-value">
                    {currentStudent.whatsappNumber}
                  </p>
                </div>
              </Grid>

              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">email</p>
                  <p className="student-details-value-email">
                    {currentStudent.email === "" ? "-" : currentStudent.email}
                  </p>
                </div>
              </Grid>

              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="students-details-card">
                  <p className="students-details-title">Monthly fees done</p>
                  {currentStudent.status === "leave" ? (
                    <div className="months">
                      {months?.map((v, i) => {
                        let trueIndex = months.findIndex(
                          (item) => item === false
                        );
                        return (
                          <button
                            key={i}
                            className="month"
                            disabled={true}
                            style={{
                              cursor: "not-allowed",
                              opacity: v ? 1 : 0.5,
                            }}
                          >
                            <p>
                              {i + 1}
                              <sup>
                                {i === 0
                                  ? "st"
                                  : i === 1
                                  ? "nd"
                                  : i === 2
                                  ? "rd"
                                  : "th"}
                              </sup>
                            </p>

                            {v ? (
                              <CheckCircleIcon
                                style={{
                                  color: "green",
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                style={{
                                  color: "red",
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                            )}
                          </button>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="months">
                      {months?.map((v, i) => {
                        let trueIndex = months.findIndex(
                          (item) => item === false
                        );
                        return (
                          <button
                            key={i}
                            className="month"
                            disabled={v ? true : i === trueIndex ? false : true}
                            style={{
                              cursor: v
                                ? "not-allowed"
                                : i === trueIndex
                                ? "pointer"
                                : "not-allowed",
                              opacity: v ? 1 : i === trueIndex ? 1 : 0.5,
                            }}
                            onClick={() => monthFeesHandler(trueIndex)}
                          >
                            <p>
                              {i + 1}
                              <sup>
                                {i === 0
                                  ? "st"
                                  : i === 1
                                  ? "nd"
                                  : i === 2
                                  ? "rd"
                                  : "th"}
                              </sup>
                            </p>

                            {v ? (
                              <CheckCircleIcon
                                style={{
                                  color: "green",
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                            ) : (
                              <CancelIcon
                                style={{
                                  color: "red",
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                            )}
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Grid>
              {currentStudent.status === "leave" && (
                <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="students-details-card">
                    <p className="students-details-title">Leave date</p>
                    <p className="student-details-value">
                      {moment(currentStudent.leaveDate).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </Grid>
              )}
              {currentStudent.status === "passed" && (
                <>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Passed date</p>
                      <p className="student-details-value">
                       {currentStudent.passedDate}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                    <div className="students-details-card">
                      <p className="students-details-title">Qr Code</p>
                      {url ? (
                        <div className="qr-code">
                          <QRCode url={url} options={options} />
                        </div>
                      ) : null}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {currentStudent.status === "active" && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xl={11} lg={10} md={10} sm={9} xs={8}></Grid>
                {isAtLeastOneRemaning ? (
                  // <Grid item xl={1} lg={2} md={2} sm={3} xs={4}></Grid>
                  <Grid item xl={1} lg={2} md={2} sm={3} xs={4}>
                    <Button
                      className="student-details-leave-button"
                      onClick={LeaveHander}
                    >
                      Leave
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xl={1} lg={2} md={2} sm={3} xs={4}>
                    <Button
                      className="student-details-passed-button"
                      onClick={PassedHander}
                    >
                      Pass
                    </Button>
                  </Grid>
                )}
                {/* {isAtLeastOneRemaning ? (
                  <Grid item xl={1} lg={2} md={2} sm={3} xs={4}></Grid>
                ) : (
                  <Grid item xl={1} lg={2} md={2} sm={3} xs={4}>
                    <Button
                      className="student-details-leave-button"
                      onClick={LeaveHander}
                    >
                      Leave
                    </Button>
                  </Grid>
                )} */}
              </Grid>
            </Box>
          )}
          <BootstrapDialog
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={"lg"}
            fullWidth
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => setOpen(false)}
            >
              <p className="model-heading">Edit Student Details</p>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div>
                        <p className="input-title">Full Name</p>
                        <input
                          type="text"
                          className="input"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Father's Name</p>
                      <input
                        type="text"
                        placeholder="Father's name"
                        value={fatherName}
                        onChange={(value) => setFatherName(value.target.value)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Caste</p>
                      <select
                        onChange={(e) => {
                          setCaste(e.target.value);
                        }}
                      >
                        <option value="" selected={caste === "" && true}>
                          Select caste
                        </option>
                        {casteList?.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.caste}
                              selected={caste === v.caste && true}
                            >
                              {v.caste}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Class</p>
                      <select onChange={(e) => setClassName(e.target.value)}>
                        <option value="" selected={className === "" && true}>
                          Select class
                        </option>
                        {classList?.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.class}
                              selected={className === v.class && true}
                            >
                              {v.class}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Gender</p>
                      <select onChange={(e) => setGender(e.target.value)}>
                        <option value="" selected={gender === "" && true}>
                          Select gender
                        </option>
                        <option
                          value="Male"
                          selected={gender === "Male" && true}
                        >
                          Male
                        </option>
                        <option
                          value="Female"
                          selected={gender === "Female" && true}
                        >
                          Female
                        </option>
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Date of Birth</p>
                      <input
                        type="date"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Course</p>
                      <select onChange={(e) => setCourse(e.target.value)}>
                        <option value="" selected={course === "" && true}>
                          Select course
                        </option>
                        {courseList?.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.course}
                              selected={course === v.course && true}
                            >
                              {v.course}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Course Duration</p>
                      <select
                        onChange={(e) => setCourseDuration(e.target.value)}
                      >
                        <option
                          value=""
                          selected={courseDuration === "" && true}
                        >
                          Select course duration
                        </option>
                        {courseDurationList?.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.courseDuration}
                              selected={
                                courseDuration === v.courseDuration && true
                              }
                            >
                              {v.courseDuration}{" "}
                              {v.courseDuration === "1" ? "Month" : "Months"}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Admission Fees</p>
                      <select
                        onChange={(e) => setAdmissionFees(e.target.value)}
                      >
                        <option
                          value=""
                          selected={admissionFees === "" && true}
                        >
                          Select admission fees
                        </option>
                        {admissionFeesList.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.admissionFees}
                              selected={
                                admissionFees === v.admissionFees && true
                              }
                            >
                              {v.admissionFees} PKR
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Batch</p>
                      <select
                        onChange={(e) => setSelectedBatch(e.target.value)}
                      >
                        <option
                          value=""
                          selected={selectedBatch === "" && true}
                        >
                          Select batch
                        </option>
                        {batch.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.batch}
                              selected={selectedBatch === v.batch && true}
                            >
                              Batch {v.batch}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Fees</p>
                      <select onChange={(e) => setFees(e.target.value)}>
                        <option value="" selected={fees === "" && true}>
                          Select fees
                        </option>
                        {feesList?.map((v, i) => {
                          return (
                            <option
                              key={i}
                              value={v.courseDuration}
                              selected={fees === `${v.fees} PKR` && true}
                            >
                              {v.fees} PKR
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Date of Admission</p>
                      <input
                        type="date"
                        value={dateOfAdmission}
                        onChange={(e) => setDateOfAdmission(e.target.value)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">WhatsApp Numbar</p>
                      <input
                        type="number"
                        placeholder="WhatsApp Numbar"
                        value={whatsappNumber}
                        onChange={(e) => setWhatsappNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <p className="input-title">Email Address (Optional)</p>
                      <input
                        type="email"
                        placeholder="Email Address (Optional)"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <br />
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={10} lg={10} md={10} sm={8} xs={12}></Grid>
                    <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
                      <Button className="edit-button" onClick={updateHandler}>
                        {updateLoading ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </DialogContent>
          </BootstrapDialog>
          <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={dateModal}
            maxWidth={"sm"}
            fullWidth
          >
            <BootstrapDialogTitle id="customized-dialog-title">
              <p className="model-heading">Passed Date</p>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <p className="input-title">Select Passed Date</p>
                      <input
                        type="date"
                        value={passedDate}
                        onChange={(e) => setPassedDate(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={8}></Grid>

                    <Grid item xl={3} lg={3} md={3} sm={3} xs={4}>
                      <Button
                        className="student-details-passed-button"
                        onClick={PassedHander2}
                      >
                        Pass
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Typography>
            </DialogContent>
          </BootstrapDialog>
        </>
      )}
    </SideMenu>
  );
};
export default StudentDetails;
