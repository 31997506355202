import React from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.css";
const ButtonCom = ({ title, isLoading, buttonType, onClick }) => {
  return (
    <div>
      {buttonType === 1 ? (
        <Button className="button" onClick={onClick}>
          {isLoading ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            `${title}`
          )}
        </Button>
      ) : buttonType === 2 ?
        <Button className="button2" onClick={onClick}>
          {isLoading ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            `${title}`
          )}
        </Button>:
        <Button className="button3" onClick={onClick}>
        {isLoading ? (
          <CircularProgress size={18} style={{ color: "white" }} />
        ) : (
          `${title}`
        )}
      </Button>
      }
    </div>
  );
};
export default ButtonCom;
