import React from "react";
import NavigationRouter from "./config/navigation";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./App.css";
const options = {
  timeout: 2500,
  position: positions.BOTTOM_CENTER,
  containerStyle: {
    zIndex: 1000000,
  },
};
const App = () => {
  return (
    <Provider template={AlertTemplate} {...options}>
      <NavigationRouter />
    </Provider>
  );
};
export default App;
