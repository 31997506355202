import React, { useEffect, useState } from "react";
import firebase from "./../../config/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../../components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  query,
  getFirestore,
  collection,
  onSnapshot,
} from "firebase/firestore";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
const columns = [
  { id: "fullName", label: "Full Name", minWidth: 120 },
  { id: "fatherName", label: "Father's Name", minWidth: 120 },
  {
    id: "caste",
    label: "Caste",
    minWidth: 100,
  },
  {
    id: "className",
    label: "Class",
    minWidth: 120,
  },
  {
    id: "gender",
    label: "Gender",
    minWidth: 50,
  },
  {
    id: "dateOfBirth",
    label: "Date of Birth",
    minWidth: 100,
  },
  {
    id: "course",
    label: "Course and Batch",
    minWidth: 150,
  },
  {
    id: "courseDuration",
    label: "Course Duration",
    minWidth: 130,
  },
  {
    id: "fees",
    label: "Fees",
    minWidth: 100,
  },
  {
    id: "admissionfees",
    label: "Admission Fees",
    minWidth: 130,
  },
  {
    id: "dateOfAdmission",
    label: "Date of Admission",
    minWidth: 150,
  },
  {
    id: "whatsappNumber",
    label: "Whatsapp Number",
    minWidth: 150,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
  },
  {
    id: "isActive",
    label: "Status",
    minWidth: 70,
  },
  {
    id: "uid",
    label: "Action",
    minWidth: 70,
  },
];
const AllStudents = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const db = getFirestore(firebase);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allStudensList, setAllStudentsList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [dropdownFilter, setDropdownFiler] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      //all studens
      const q = query(collection(db, "students"));
      onSnapshot(q, (querySnapshot) => {
        const allStunts = [];
        setLoading(false);
        querySnapshot.forEach((doc) => {
          allStunts.push(doc.data());
          setAllStudentsList([...allStunts]);
        });
      });
    }
    fetchMyAPI();
  }, []);

  const filteredUsers = allStudensList.filter((users) => {
    if (searchType === "" || searchType === "input") {
      return (
        users.fullName?.toLowerCase().includes(search.toLowerCase()) ||
        users.course?.toLowerCase().includes(search.toLowerCase()) ||
        users.batch?.toLowerCase().includes(search.toLowerCase())
      );
    } else if (searchType === "dropdown") {
      if (dropdownFilter === "All") {
        return users;
      } else if (dropdownFilter === "Active") {
        return users.status
          ?.toLowerCase()
          .includes(dropdownFilter.toLowerCase());
      } else if (dropdownFilter === "Leave") {
        return users.status
          ?.toLowerCase()
          .includes(dropdownFilter.toLowerCase());
      } else if (dropdownFilter === "Passed") {
        return users.status
          ?.toLowerCase()
          .includes(dropdownFilter.toLowerCase());
      }
    }
  });
  return (
    <SideMenu>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={10} lg={10} md={9} sm={8} xs={12}>
            <div>
              <p className="input-title">Filter by full name or course name</p>
              <input
                type="text"
                className="input"
                placeholder="Search by full name or course name"
                value={search}
                onChange={(e) => {
                  setSearchType("input");
                  setSearch(e.target.value);
                  setDropdownFiler("All");
                }}
              />
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <p className="input-title">Filter by status</p>
            <select
              onChange={(e) => {
                setSearchType("dropdown");
                setDropdownFiler(e.target.value);
                setSearch("");
              }}
            >
              <option value="All" selected={dropdownFilter === "All" && true}>
                All
              </option>
              <option value="Active">Active</option>
              <option value="Leave">Leave</option>
              <option value="Passed">Passed</option>
            </select>
          </Grid>
        </Grid>
      </Box>
      <br />
      <br />
      {loading ? (
        <p className="loading">Loading...</p>
      ) : filteredUsers.length === 0 ? (
        <p>No data available</p>
      ) : (
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "65vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" colSpan={14}>
                    All Students
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell>{row.fullName}</TableCell>
                        <TableCell>{row.fatherName}</TableCell>
                        <TableCell>{row.caste}</TableCell>
                        <TableCell>{row.className}</TableCell>
                        <TableCell>{row.gender}</TableCell>
                        <TableCell>{row.dateOfBirth}</TableCell>
                        <TableCell>
                          {row.course} Batch({row.batch})
                        </TableCell>
                        <TableCell>
                          {row.courseDuration}{" "}
                          {row.courseDuration === "1" ? "Month" : "Months"}
                        </TableCell>
                        <TableCell>{row.fees} PKR</TableCell>
                        <TableCell>{row.admissionFees} PKR</TableCell>
                        <TableCell>{row.dateOfAdmission}</TableCell>
                        <TableCell>{row.whatsappNumber}</TableCell>
                        <TableCell>
                          {row.email === "" ? (
                            <p style={{ textAlign: "center" }}>-</p>
                          ) : (
                            <p style={{ textTransform: "lowercase" }}>
                              {row.email}
                            </p>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.status === "active" && (
                            <div
                              className="table-status-box"
                              style={{ backgroundColor: "#00a75f" }}
                            >
                              <p>Active</p>
                            </div>
                          )}
                          {row.status === "leave" && (
                            <div
                              className="table-status-box"
                              style={{ backgroundColor: "#dd4b39" }}
                            >
                              <p>Leave</p>
                            </div>
                          )}
                          {row.status === "passed" && (
                            <div
                              className="table-status-box"
                              style={{ backgroundColor: "#f39c12" }}
                            >
                              <p>Passed</p>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            className="view-button"
                            onClick={() =>
                              navigate(`/student-details/${row.uid}`)
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </SideMenu>
  );
};
export default AllStudents;
