import React, { useEffect, useState } from "react";
import firebase from "./../../config/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../../components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./index.css";
import AllStudentsIcon from "./../../images/all-students.png";
import ActiveStudentsIcon from "./../../images/active-students.png";
import PassedStudentsIcon from "./../../images/pass-students.png";
import LeaveStudentsIcon from "./../../images/leave-students.png";
import CircularProgress from "@mui/material/CircularProgress";
import ProfitsIcon from "./../../images/profits.png";
import WebDevelopmentIcon from "./../../images/web-development.png";
import GraphicIcon from "./../../images/graphic.png";
import WordPressIcon from "./../../images/wordpress.png";
import BasicIcon from "./../../images/basic.png";
import ActiveIcon from "./../../images/active.png";
import GraduatedIcon from "./../../images/graduation.png";
import LeftIcon from "./../../images/left.png";
import SocialMediaMarketing from "./../../images/social-media.png";
import {
  query,
  getFirestore,
  collection,
  onSnapshot,
  where,
} from "firebase/firestore";
const Dashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const db = getFirestore(firebase);
  const [allUsers, setAllUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [passedUsers, setPassedUsers] = useState(0);
  const [leaveUsers, setLeaveUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allStudensList, setAllStudentsList] = useState([]);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      //all studens
      const q = query(collection(db, "students"));
      onSnapshot(q, (querySnapshot) => {
        const allStudents = [];
        setLoading(false);
        querySnapshot.forEach((doc) => {
          allStudents.push(doc.data());
        });
        setAllStudentsList([...allStudents]);
        setAllUsers(querySnapshot.size);
      });
      //active studens
      const qActive = query(
        collection(db, "students"),
        where("status", "==", "active")
      );
      onSnapshot(qActive, (querySnapshot) => {
        setActiveUsers(querySnapshot.size);
      });
      //passed studens
      const qPassed = query(
        collection(db, "students"),
        where("status", "==", "passed")
      );
      onSnapshot(qPassed, (querySnapshot) => {
        setPassedUsers(querySnapshot.size);
      });
      //passed studens
      const qLeave = query(
        collection(db, "students"),
        where("status", "==", "leave")
      );
      onSnapshot(qLeave, (querySnapshot) => {
        setLeaveUsers(querySnapshot.size);
        setLoading(false);
      });
    }
    fetchMyAPI();
  }, []);


  const filteredActiveUsers = allStudensList.filter(
    (obj) => obj.status === "active"
  );
  const totalFees = allStudensList.reduce(
    (total, data) => total + Number(data.totalFessCollected),
    0
  );
  const totalAdmissonFees = allStudensList.reduce(
    (total, data) => total + Number(data.admissionFees),
    0
  );
  const totalPirce = totalFees + totalAdmissonFees;
  // web developmet
  const webDevelopmentFilteredUsers = allStudensList.filter((users) => {
    return users.course?.toLowerCase().includes("web development");
  });
  // web developmet active student
  const webDevelopmentFilteredActiveUsers = webDevelopmentFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("active");
    }
  );
  // web developmet  left
  const webDevelopmentFilteredLeftUsers = webDevelopmentFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("leave");
    }
  );
  // web developmet  Graduated
  const webDevelopmentFilteredGraduatedUsers =
    webDevelopmentFilteredUsers.filter((users) => {
      return users.status?.toLowerCase().includes("passed");
    });
  const webDevelopmentTotal =
    webDevelopmentFilteredUsers.reduce(
      (total, data) => total + Number(data.totalFessCollected),
      0
    ) +
    webDevelopmentFilteredUsers.reduce(
      (total, data) => total + Number(data.admissionFees),
      0
    );
  //graphic desing
  const graphictFilteredUsers = allStudensList.filter((users) => {
    return users.course?.toLowerCase().includes("graphic design");
  });
  // graphic desing  student
  const graphictFilteredActiveUsers = graphictFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("active");
  });
  // graphic desing  left
  const graphictFilteredLeftUsers = graphictFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("leave");
  });
  // graphic desing  Graduated
  const graphictFilteredGraduatedUsers = graphictFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("passed");
    }
  );
  const graphicTotal =
    graphictFilteredUsers.reduce(
      (total, data) => total + Number(data.totalFessCollected),
      0
    ) +
    graphictFilteredUsers.reduce(
      (total, data) => total + Number(data.admissionFees),
      0
    );
  // basic

  let basic = "Digital Literacy Training Program ( Basic Course )";

  const basicFilteredUsers = allStudensList.filter((users) => {
    return users.course?.toLowerCase().includes(basic.toLocaleLowerCase());
    // return users;
  });
  // basic  student
  const basicFilteredActiveUsers = basicFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("active");
  });
  // basic  left
  const basicFilteredLeftUsers = basicFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("leave");
  });
  // basic  Graduated
  const basicFilteredGraduatedUsers = basicFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("passed");
  });
  const basicTotal =
    basicFilteredUsers.reduce(
      (total, data) => total + Number(data.totalFessCollected),
      0
    ) +
    basicFilteredUsers.reduce(
      (total, data) => total + Number(data.admissionFees),
      0
    );

  // wordpress
  const wordpressFilteredUsers = allStudensList.filter((users) => {
    return users.course?.toLowerCase().includes("wordpress");
  });

  // wordpress  student
  const wordpressFilteredActiveUsers = wordpressFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("active");
    }
  );
  // wordpress  left
  const wordpressFilteredLeftUsers = wordpressFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("leave");
  });
  // wordpress  Graduated
  const wordpressFilteredGraduatedUsers = wordpressFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("passed");
    }
  );
  const wordpressTotal =
    wordpressFilteredUsers.reduce(
      (total, data) => total + Number(data.totalFessCollected),
      0
    ) +
    wordpressFilteredUsers.reduce(
      (total, data) => total + Number(data.admissionFees),
      0
    );

  // social media marketing
  const SocialMediaMarketingFilteredUsers = allStudensList.filter((users) => {
    return users.course?.toLowerCase().includes("social media marketing");
  });
  //social media marketing total
  const SocialMediaMarketingTotal =
    SocialMediaMarketingFilteredUsers.reduce(
      (total, data) => total + Number(data.totalFessCollected),
      0
    ) +
    SocialMediaMarketingFilteredUsers.reduce(
      (total, data) => total + Number(data.admissionFees),
      0
    );

     // social media marketing active student
  const SocialMediaMarketingFilteredActiveUsers = SocialMediaMarketingFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("active");
    }
  );
  // social media marketing  left
  const SocialMediaMarketingFilteredLeftUsers = SocialMediaMarketingFilteredUsers.filter((users) => {
    return users.status?.toLowerCase().includes("leave");
  });
  // social media marketing  Graduated
  const SocialMediaMarketingFilteredGraduatedUsers = SocialMediaMarketingFilteredUsers.filter(
    (users) => {
      return users.status?.toLowerCase().includes("passed");
    }
  );
  return (
    <SideMenu>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={AllStudentsIcon} />
              <h1 className="box-heading">
                All Students:{" "}
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  allUsers
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={ActiveStudentsIcon} />
              <h1 className="box-heading">
                Active:{" "}
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  activeUsers
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={PassedStudentsIcon} />
              <h1 className="box-heading">
                Graduated:{" "}
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  passedUsers
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={LeaveStudentsIcon} />
              <h1 className="box-heading">
                Left:{" "}
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  leaveUsers
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={ProfitsIcon} />
              <h1 className="box-heading">Total revenue generated</h1>
              <h1 className="box-heading">
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  totalPirce.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box ">
              <img src={WebDevelopmentIcon} />
              <h1 className="box-heading">
                Web Development ({webDevelopmentFilteredUsers.length})
              </h1>
              <h1 className="box-heading">
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  webDevelopmentTotal.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={GraphicIcon} />
              <h1 className="box-heading">
                Graphic Design ({graphictFilteredUsers.length})
              </h1>
              <h1 className="box-heading">
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  graphicTotal.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={WordPressIcon} />
              <h1 className="box-heading">
                WordPress ({wordpressFilteredUsers.length})
              </h1>
              <h1 className="box-heading">
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  wordpressTotal.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={BasicIcon} />
              <h1 className="box-heading">
                Basic Course ({basicFilteredUsers.length})
              </h1>
              <h1 className="box-heading">
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  basicTotal.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                )}
              </h1>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className="header-box">
              <img src={SocialMediaMarketing} />
              <h1 className="box-heading">
                Social Media Marketing (
                {SocialMediaMarketingFilteredUsers.length})
              </h1>
              <h1 className="box-heading">
                {loading ? (
                  <CircularProgress className="header-box-loader" />
                ) : (
                  SocialMediaMarketingTotal.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })
                )}
              </h1>
            </div>
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: "20px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="dashboard-box">
                <div className="dashboard-status-box-main">
                  <div>
                    <p>Active</p>
                    <div className="dashboard-status-box-active">
                      <img src={ActiveIcon} />
                      <p>{webDevelopmentFilteredActiveUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Graduated</p>
                    <div className="dashboard-status-box-graduated">
                      <img src={GraduatedIcon} />
                      <p>{webDevelopmentFilteredGraduatedUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Left</p>
                    <div className="dashboard-status-box-left">
                      <img src={LeftIcon} />
                      <p>{webDevelopmentFilteredLeftUsers.length}</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard-status-box-icon">
                  <img src={WebDevelopmentIcon} />
                </div>
              </div>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="dashboard-box">
                <div className="dashboard-status-box-main">
                  <div>
                    <p>Active</p>
                    <div className="dashboard-status-box-active">
                      <img src={ActiveIcon} />
                      <p>{graphictFilteredActiveUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Graduated</p>
                    <div className="dashboard-status-box-graduated">
                      <img src={GraduatedIcon} />
                      <p>{graphictFilteredGraduatedUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Left</p>
                    <div className="dashboard-status-box-left">
                      <img src={LeftIcon} />
                      <p>{graphictFilteredLeftUsers.length}</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard-status-box-icon">
                  <img src={GraphicIcon} />
                </div>
              </div>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="dashboard-box">
                <div className="dashboard-status-box-main">
                  <div>
                    <p>Active</p>
                    <div className="dashboard-status-box-active">
                      <img src={ActiveIcon} />
                      <p>{wordpressFilteredActiveUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Graduated</p>
                    <div className="dashboard-status-box-graduated">
                      <img src={GraduatedIcon} />
                      <p>{wordpressFilteredGraduatedUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Left</p>
                    <div className="dashboard-status-box-left">
                      <img src={LeftIcon} />
                      <p>{wordpressFilteredLeftUsers.length}</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard-status-box-icon">
                  <img src={WordPressIcon} />
                </div>
              </div>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="dashboard-box">
                <div className="dashboard-status-box-main">
                  <div>
                    <p>Active</p>
                    <div className="dashboard-status-box-active">
                      <img src={ActiveIcon} />
                      <p>{basicFilteredActiveUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Graduated</p>
                    <div className="dashboard-status-box-graduated">
                      <img src={GraduatedIcon} />
                      <p>{basicFilteredGraduatedUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Left</p>
                    <div className="dashboard-status-box-left">
                      <img src={LeftIcon} />
                      <p>{basicFilteredLeftUsers.length}</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard-status-box-icon">
                  <img src={BasicIcon} />
                </div>
              </div>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="dashboard-box">
                <div className="dashboard-status-box-main">
                  <div>
                    <p>Active</p>
                    <div className="dashboard-status-box-active">
                      <img src={ActiveIcon} />
                      <p>{SocialMediaMarketingFilteredActiveUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Graduated</p>
                    <div className="dashboard-status-box-graduated">
                      <img src={GraduatedIcon} />
                      <p>{SocialMediaMarketingFilteredGraduatedUsers.length}</p>
                    </div>
                  </div>
                  <div>
                    <p>Left</p>
                    <div className="dashboard-status-box-left">
                      <img src={LeftIcon} />
                      <p>{SocialMediaMarketingFilteredLeftUsers.length}</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard-status-box-icon">
                  <img src={SocialMediaMarketing} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </SideMenu>
  );
};
export default Dashboard;
