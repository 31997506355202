import React, { useEffect, useState } from "react";
import "./side-menu-data.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IoLogOutSharp } from "react-icons/io5";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "./../../config/firebase";
import Logo from "./../../images/logo-2.png";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
const SideMenuData = () => {
  const db = getFirestore(firebase);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [userName, setUserName] = useState("");
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const q = query(collection(db, "admin"));
        async function fetchMyAPI() {
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if (user.uid === doc.data().uid) {
              setUserName(doc.data().fullName);
            }
          });
        }

        fetchMyAPI();
      }
    });
  }, []);
  let CurrentPagePath = location.pathname;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      {isMatch ? (
        <div></div>
      ) : (
        <div
          className="tutor-panel-sider-container"
          style={{ position: isMatch ? "" : "fixed" }}
        >
          <div className="tutor-panel-sider-content-wraper">
            <div className="tutor-panel-sider-logo-container">
              <img src={Logo} alt="logo" width="150px" />
            </div>
            <div className="side-menu-data-list-main">
              <ul className="side-menu-ul">
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/dashboard"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <DashboardIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">dashboard</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/all-students"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/all-students");
                    }}
                  >
                    <Diversity3Icon className="side-menu-icon" />
                    <span className="side-menu-page-title">all students</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-student"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-student");
                    }}
                  >
                    <GroupAddIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">Add Student</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-caste"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-caste");
                    }}
                  >
                    <BookmarkAddIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">Add Caste</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-class"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-class");
                    }}
                  >
                    <AddBusinessIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">Add Class</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-course"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-course");
                    }}
                  >
                    <DomainAddIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">Add Course</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-course-duration"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-course-duration");
                    }}
                  >
                    <AddAlarmIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">
                      Add Course Duration
                    </span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-fees"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-fees");
                    }}
                  >
                    <CurrencyExchangeIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">Add fees</span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-admission-fees"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-admission-fees");
                    }}
                  >
                    <CurrencyRupeeIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">
                      add admission fees
                    </span>
                  </Button>
                </li>
                <li className="tutor-panel-sider-list-item">
                  <Button
                    variant="text"
                    className={
                      CurrentPagePath === "/add-batch"
                        ? "side-menu-active-page"
                        : "side-menu-page"
                    }
                    onClick={() => {
                      navigate("/add-batch");
                    }}
                  >
                    <BatchPredictionIcon className="side-menu-icon" />
                    <span className="side-menu-page-title">add batch</span>
                  </Button>
                </li>
                <br />
              </ul>
            </div>
            <div className="first-tutor-panel-sider-log-out-container">
              <Button
                variant="text"
                className="logout-button"
                onClick={() => {
                  signOut(auth).then(() => {
                    navigate("/");
                  });
                }}
              >
                <span>Logout</span>
                <IoLogOutSharp className="side-menu-icon" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SideMenuData;
